import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';

// Components
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    margin: theme.spacing(4, 0)
  },
  loaderText: {
    marginTop: theme.spacing(4)
  },
  skChase: {
    width: '80px',
    height: '80px',
    position: 'relative',
    animation: '$sk-chase 2.5s infinite linear both'
  },
  skChaseDot: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    animation: '$sk-chase-dot 2s infinite ease-in-out both',
    '&:before': {
      content: '""',
      display: 'block',
      width: '25%',
      height: '25%',
      backgroundColor: 'black',
      borderRadius: '100%',
      animation: '$sk-chase-dot-before 2s infinite ease-in-out both'
    },
    '&:nth-child(1)': {
      animationDelay: '-1.1s',
      '&:before': {
        animationDelay: '-1.1s'
      }
    },
    '&:nth-child(2)': {
      animationDelay: '-1s',
      '&:before': {
        animationDelay: '-1s'
      }
    },
    '&:nth-child(3)': {
      animationDelay: '-0.9s',
      '&:before': {
        animationDelay: '-0.9s'
      }
    },
    '&:nth-child(4)': {
      animationDelay: '-0.8s',
      '&:before': {
        animationDelay: '-0.8s'
      }
    },
    '&:nth-child(5)': {
      animationDelay: '-0.7s',
      '&:before': {
        animationDelay: '-0.7s'
      }
    },
    '&:nth-child(6)': {
      animationDelay: '-0.6s',
      '&:before': {
        animationDelay: '-0.6s'
      }
    }
  },
  '@keyframes sk-chase': {
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  '@keyframes sk-chase-dot': {
    '80%, 100%': {
      transform: 'rotate(360deg)'
    }
  },
  '@keyframes sk-chase-dot-before': {
    '50%': {
      transform: 'scale(0.4)'
    },
    '100%, 0%': {
      transform: 'scale(1)'
    }
  }
}));

const Loader = ({ text, styles }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={clsx(classes.loaderWrapper, styles)}
    >
      <div className={classes.skChase}>
        <div className={classes.skChaseDot}></div>
        <div className={classes.skChaseDot}></div>
        <div className={classes.skChaseDot}></div>
        <div className={classes.skChaseDot}></div>
        <div className={classes.skChaseDot}></div>
        <div className={classes.skChaseDot}></div>
      </div>
      <Typography className={classes.loaderText}>{text}</Typography>
    </Grid>
  );
};

Loader.propTypes = {
  text: string.isRequired,
  styles: string
};

Loader.defaultProps = {
  styles: ''
};

export default Loader;
