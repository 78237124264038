import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Switch } from 'react-router-dom';
import { checkMe, setLoading } from './actions/routes-actions';
import RouteWithLayout from './components/RouteWithLayout';

import { Main, Minimal } from './layouts';
import {
  SignIn,
  SignOut,
  NotFound,
  Dashboard,
  AddCampaign,
  CampaignsList,
  SingleCampaign
} from 'components/modules';
import Loader from 'components/common/Loader';

import { history } from 'components/App';

class Routes extends React.Component {
  constructor(props) {
    super(props);
    let authorization = !['/sign-out'].includes(history.location.pathname) && props.auth;
    if (authorization) {
      props.setLoading(true);
    }

    let page = {
      loading: authorization,
      auth: authorization,
      AuthLoad: () => <Loader text="Authorization token check" />
    };

    this.state = {
      ...page
    };
  }

  componentWillReceiveProps(nextProps) {
    let auth = !['/sign-out'].includes(history.location.pathname) && nextProps.auth;
    if (auth !== this.props.auth) {
      if (auth) {
        this.props.checkMe();
      }
      this.setState({ auth });
    }
  }

  componentDidMount = function () {
    if (this.state.auth) {
      this.props.checkMe();
    } else {
      this.props.setLoading(false);
    }
  };

  render() {
    let { auth, loading } = this.props;
    let { AuthLoad } = this.state;

    if (loading) {
      return (
        <Switch>
          <RouteWithLayout component={AuthLoad} layout={Minimal} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return auth ? (
      // User router
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/sign-in" to="/dashboard" />

        <RouteWithLayout path="/sign-out" component={SignOut} exact layout={Main} />
        <RouteWithLayout path="/dashboard" component={Dashboard} exact layout={Main} />

        {/* Campaign routes */}
        <RouteWithLayout path="/campaigns" component={CampaignsList} exact layout={Main} />
        <RouteWithLayout path="/campaigns/create" component={AddCampaign} exact layout={Main} />
        <RouteWithLayout path="/campaigns/:id" component={SingleCampaign} exact layout={Main} />

        <RouteWithLayout component={NotFound} layout={Minimal} />
      </Switch>
    ) : (
      // Guest router
      <Switch>
        <Redirect exact from="/" to="/sign-in" />
        <Redirect exact from="/dashboard" to="/sign-in" />
        <Redirect exact from="/sign-out" to="/sign-in" />

        <RouteWithLayout path="/sign-in" component={SignIn} exact layout={Minimal} />

        <RouteWithLayout component={NotFound} layout={Minimal} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.route.get('loading'),
    auth: state.auth.get('token')
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkMe,
      setLoading
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
