import {
  getApis,
  getIsnList,
  getCampaign,
  getCarriers,
  getShortDomains,
  getCampaignsList,
  createNewCampaign,
  getCampaignOptions,
  getCampaignsLabels,
  getCampaignsStatistics,
  getCampaignContactsList,
  getSecondPredefinedBodies,
  getInitialPredefinedBodies
} from '../actions/campaigns-actions';
import { REQUEST_STATUSES } from '../constants/common';

const initialState = {
  campaignsList: [],
  singleCampaign: {
    settings: {
      contacts_unique_only: 0,
      contacts_from: null,
      contacts_to: null
    },
    resend: null,
    bodies: [],
    predefined_bodies: [],
    apis: [],
    offers: [],
    lists: [],
    isn_lists: [],
    messages_sent_cost: 0.0,
    messages_total_cost: 0.0,
    campaign_type: {
      name: '',
      id: 0
    },
    order_message: {
      name: ''
    },
    country_type: {
      name: ''
    },
    encoding_data: {
      name: ''
    }
  },
  campaignsListMetaData: {
    total: 0,
    additional: {
      statuses: []
    }
  },
  campaignOptions: {},
  campaignShortDomains: [],
  apis: [],
  isnList: [],
  carriers: [],
  campaignsLabels: [],
  initialPredefinedBodies: [],
  secondPredefinedBodies: [],
  campaignsStatistics: [],
  campaignContactsList: [],
  singleCampaignRequestStatus: REQUEST_STATUSES.REQUEST,
  isnListRequestStatus: REQUEST_STATUSES.REQUEST,
  createNewCampaignRequestStatus: REQUEST_STATUSES.REQUEST,
  initialPredefinedBodiesRequestStatus: REQUEST_STATUSES.REQUEST,
  secondPredefinedBodiesRequestStatus: REQUEST_STATUSES.REQUEST,
  campaignContactsListRequestStatus: REQUEST_STATUSES.REQUEST,
  campaignOptionsRequestStatus: REQUEST_STATUSES.REQUEST,
  campaignShortDomainsRequestStatus: REQUEST_STATUSES.REQUEST,
  campaignsStatisticsRequestStatus: REQUEST_STATUSES.REQUEST,
  campaignsListRequestStatus: REQUEST_STATUSES.REQUEST,
  carriersRequestStatus: REQUEST_STATUSES.REQUEST,
  apisRequestStatus: REQUEST_STATUSES.REQUEST,
  campaignsLabelsRequestStatus: REQUEST_STATUSES.REQUEST
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getCampaignsList.request.getType():
      return {
        ...state,
        campaignsListRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getCampaignsList.ok.getType():
      return {
        ...state,
        campaignsListRequestStatus: REQUEST_STATUSES.SUCCESS,
        campaignsList: action.payload.response.data,
        campaignsListMetaData: action.payload.response.meta
      };
    case getCampaignsList.error.getType():
      return {
        ...state,
        campaignsList: [],
        campaignsListRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case getCampaign.request.getType():
      return {
        ...state,
        singleCampaignRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getCampaign.ok.getType():
      return {
        ...state,
        singleCampaignRequestStatus: REQUEST_STATUSES.SUCCESS,
        singleCampaign: action.payload.response.data
      };
    case getCampaign.error.getType():
      return {
        ...state,
        singleCampaign: {},
        singleCampaignRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case createNewCampaign.request.getType():
      return {
        ...state,
        createNewCampaignRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case createNewCampaign.ok.getType():
      return {
        ...state,
        campaignsList: [...state.campaignsList, action.payload.response.data],
        createNewCampaignRequestStatus: REQUEST_STATUSES.SUCCESS
      };
    case createNewCampaign.error.getType():
      return {
        ...state,
        createNewCampaignRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case getApis.request.getType():
      return {
        ...state,
        apisRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getApis.ok.getType():
      return {
        ...state,
        apisRequestStatus: REQUEST_STATUSES.SUCCESS,
        apis: action.payload.response.data
      };
    case getApis.error.getType():
      return {
        ...state,
        apis: [],
        apisRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case getCarriers.request.getType():
      return {
        ...state,
        carriersRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getCarriers.ok.getType():
      return {
        ...state,
        carriersRequestStatus: REQUEST_STATUSES.SUCCESS,
        carriers: action.payload.response.data
      };
    case getCarriers.error.getType():
      return {
        ...state,
        carriers: [],
        carriersRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case getCampaignsLabels.request.getType():
      return {
        ...state,
        campaignsLabelsRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getCampaignsLabels.ok.getType():
      return {
        ...state,
        campaignsLabelsRequestStatus: REQUEST_STATUSES.SUCCESS,
        campaignsLabels: action.payload.response.data
      };
    case getCampaignsLabels.error.getType():
      return {
        ...state,
        campaignsLabels: [],
        campaignsLabelsRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case getCampaignsStatistics.request.getType():
      return {
        ...state,
        campaignsStatisticsRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getCampaignsStatistics.ok.getType():
      return {
        ...state,
        campaignsStatisticsRequestStatus: REQUEST_STATUSES.SUCCESS,
        campaignsStatistics: action.payload.response.data
      };
    case getCampaignsStatistics.error.getType():
      return {
        ...state,
        campaignsStatistics: [],
        campaignsStatisticsRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case getInitialPredefinedBodies.request.getType():
      return {
        ...state,
        initialPredefinedBodiesRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getInitialPredefinedBodies.ok.getType():
      return {
        ...state,
        initialPredefinedBodiesRequestStatus: REQUEST_STATUSES.SUCCESS,
        initialPredefinedBodies: action.payload.response.data
      };
    case getInitialPredefinedBodies.error.getType():
      return {
        ...state,
        initialPredefinedBodies: [],
        initialPredefinedBodiesRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case getSecondPredefinedBodies.request.getType():
      return {
        ...state,
        secondPredefinedBodiesRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getSecondPredefinedBodies.ok.getType():
      return {
        ...state,
        secondPredefinedBodiesRequestStatus: REQUEST_STATUSES.SUCCESS,
        secondPredefinedBodies: action.payload.response.data
      };
    case getSecondPredefinedBodies.error.getType():
      return {
        ...state,
        secondPredefinedBodies: [],
        secondPredefinedBodiesRequestStatus: REQUEST_STATUSES.FAILURE
      };
    case getCampaignOptions.request.getType():
      return {
        ...state,
        campaignOptionsRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getCampaignOptions.ok.getType():
      return {
        ...state,
        campaignOptionsRequestStatus: REQUEST_STATUSES.SUCCESS,
        campaignOptions: action.payload.response.data
      };
    case getCampaignOptions.error.getType():
      return {
        ...state,
        campaignOptionsRequestStatus: REQUEST_STATUSES.FAILURE,
        campaignOptions: {}
      };
    case getCampaignContactsList.request.getType():
      return {
        ...state,
        campaignContactsListRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getCampaignContactsList.ok.getType():
      return {
        ...state,
        campaignContactsListRequestStatus: REQUEST_STATUSES.SUCCESS,
        campaignContactsList: action.payload.response.data
      };
    case getCampaignContactsList.error.getType():
      return {
        ...state,
        campaignContactsListRequestStatus: REQUEST_STATUSES.FAILURE,
        campaignContactsList: []
      };
    case getShortDomains.request.getType():
      return {
        ...state,
        campaignShortDomainsRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getShortDomains.ok.getType():
      return {
        ...state,
        campaignShortDomainsRequestStatus: REQUEST_STATUSES.SUCCESS,
        campaignShortDomains: action.payload.response.data
      };
    case getShortDomains.error.getType():
      return {
        ...state,
        campaignShortDomainsRequestStatus: REQUEST_STATUSES.FAILURE,
        campaignShortDomains: []
      };
    case getIsnList.request.getType():
      return {
        ...state,
        isnListRequestStatus: REQUEST_STATUSES.REQUEST
      };
    case getIsnList.ok.getType():
      return {
        ...state,
        isnListRequestStatus: REQUEST_STATUSES.SUCCESS,
        isnList: action.payload.response.data
      };
    case getIsnList.error.getType():
      return {
        ...state,
        isnListRequestStatus: REQUEST_STATUSES.FAILURE,
        isnList: []
      };
    default:
      return state;
  }
};

export default reducer;
