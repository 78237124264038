import { Map } from 'immutable';

export const constants = {
  GOOGLE_ERRORS: 'GOOGLE_ERRORS',
  AUTH_REGISTER: 'AUTH_REGISTER',
  AUTH_CHECK: 'AUTH_CHECK',
  AUTH_LOGIN: 'AUTH_LOGIN',
  AUTH_LOGIN_FAILED: 'AUTH_LOGIN_FAILED',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_LOGOUT_FAILED: 'AUTH_LOGOUT_FAILED'
};

const defaultAuth = Map({
  token: localStorage.getItem('token'),
  me: false
});

export default function authReducer(state = defaultAuth, { type, payload }) {
  switch (type) {
    case 'AUTH_CHECK':
      return state.set('me', payload);
    case 'AUTH_LOGIN':
      return state.set('token', payload);
    case 'AUTH_LOGOUT':
      return state.set('token', null);
    case 'AUTH_REGISTER':
      return {
        ...state
      };
    default:
      return state;
  }
}
