import { lazy } from 'react';

const NotFound = lazy(() => import('components/modules/NotFound'));
const SignIn = lazy(() => import('components/modules/SignIn'));
const SignUp = lazy(() => import('components/modules/SignUp'));
const SignOut = lazy(() => import('components/modules/SignOut/SignOut'));
const Dashboard = lazy(() => import('components/modules/Dashboard'));

// Campaigns
const CampaignsList = lazy(() =>
  import('components/modules/Campaigns/CampaignsList/CampaignsList')
);
const SingleCampaign = lazy(() =>
  import('components/modules/Campaigns/SingleCampaign/SingleCampaign')
);
const AddCampaign = lazy(() => import('components/modules/Campaigns/AddCampaign'));

export { NotFound, SignIn, SignUp, SignOut, Dashboard, CampaignsList, AddCampaign, SingleCampaign };
