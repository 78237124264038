import { isEmpty } from 'validate.js';

export const campaignsFiltersUrlBuilder = (filters) => {
  let filterUrl = '';
  for (const key in filters) {
    filterUrl += filters[key].reduce((acc, item) => `${acc}&filter[${key}][]=${item}`, '');
  }
  return filterUrl;
};

export const newCampaignContactsUrlBuilder = (filters) => {
  let filterUrl = '';
  const { unique_today, date_added, ...restFilters } = filters;
  if (!isEmpty(restFilters)) {
    for (const key in restFilters) {
      filterUrl += restFilters[key].reduce(
        (acc, item) => (!isEmpty(item) ? `${acc}&${key}[]=${item}` : acc),
        ''
      );
    }
  }
  if (date_added.startDate && date_added.endDate) {
    filterUrl += `&date_added=${date_added.startDate} - ${date_added.endDate}`;
  }
  return `&unique_today=${unique_today}${filterUrl}`;
};

export const campaignsStatisticsUrlBuilder = (ids) => {
  return ids.reduce((acc, item, index) => {
    const delimiter = !index ? '?' : '&';
    return `${acc}${delimiter}id[]=${item}`;
  }, '');
};
