import { Map } from 'immutable';

const defaultRoute = Map({
  loading: true
});

export default function routeReducer(state = defaultRoute, { type, payload }) {
  switch (type) {
    case 'ROUTE_LOADING':
      return state.set('loading', payload);
    default:
      return state;
  }
}
