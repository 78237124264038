const MuiTypography = {
  gutterBottom: {
    marginBottom: '1.3rem'
  },
  h4: {
    fontSize: '1.75rem',
    lineHeight: 1
  },
  body1: {
    fontWeight: 500
  },
  body2: {
    fontSize: '.95rem',
    lineHeight: 1.65
  }
};

export default MuiTypography;
