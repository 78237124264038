import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  ExitToApp,
  SwapHoriz as SwapHorizIcon
} from '@material-ui/icons';
import {
  AppBar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  IconButton,
  Divider,
  Fade,
  Grid,
  Toolbar,
  Link
} from '@material-ui/core';
import palette from 'theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    zIndex: 1200
  },
  toolbar: {
    minHeight: theme.spacing(4),
    height: theme.spacing(8),
    boxSizing: 'border-box',
    padding: '10px 40px 10px 20px',
    justifyContent: 'space-between',
    backgroundColor: palette.aside.backgroundColor,
    color: palette.white
  },
  iconButton: {
    padding: theme.spacing(2),
    '&:hover': {
      background: 'black'
    }
  }
}));

const Topbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const history = useHistory();
  const { className, onSidebarOpen, isDesktop, sidebarOpen, ...rest } = props;

  const handleToggle = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    handleClose();
    history.push('/sign-out');
  };

  let topbarProps = {
    ...rest,
    me: (typeof me == 'object').toString()
  };

  return (
    <AppBar
      {...topbarProps}
      className={clsx(classes.root, className)}
      color="default"
      position="fixed"
    >
      <Toolbar className={classes.toolbar}>
        {!isDesktop && (
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        )}

        <Grid container justify="flex-end" alignItems="center">
          <Link href={process.env.REACT_APP_API}>
            <IconButton color="inherit" className={classes.iconButton}>
              <SwapHorizIcon width={27} height={27} color="primary" />
            </IconButton>
          </Link>

          <IconButton
            color="inherit"
            aria-controls="header-menu"
            onClick={handleToggle}
            className={classes.iconButton}
          >
            <AccountCircleIcon width={27} height={27} />
          </IconButton>
        </Grid>

        <Menu
          id="header-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          getContentAnchorEl={null}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {'me' in props && typeof props.me === 'object' && (
            <ListItem key="userInfo" button={false} disabled>
              <ListItemText primary={props.me.name} />
            </ListItem>
          )}
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToApp style={{ width: 18, height: 18 }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  isDesktop: PropTypes.bool,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    me: state.auth.get('me')
  };
}

export default connect(mapStateToProps, {})(Topbar);
