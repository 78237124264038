import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import overrides from './overrides';

let theme = createMuiTheme({
  palette,
  contrastThreshold: 3,
  overrides
});

export default theme;
