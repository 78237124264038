import { createActionAsync } from 'redux-act-async';
import { isEmpty } from 'lodash';
import {
  campaignsFiltersUrlBuilder,
  campaignsStatisticsUrlBuilder,
  newCampaignContactsUrlBuilder
} from 'helpers/action-helpers';

import API from 'api/API';

export const getCampaignsList = createActionAsync(
  'GET_CAMPAIGNS_LIST',
  (offset, limit, filters = {}) => {
    let filtersQuery = '';

    if (!isEmpty(filters)) {
      filtersQuery = campaignsFiltersUrlBuilder(filters);
    }

    return API.get(
      `/campaigns?sort[]=-id&page[offset]=${offset}&page[limit]=${limit}${filtersQuery}`
    )
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }
);

export const getCampaign = createActionAsync('GET_CAMPAIGN', (campaignId) =>
  API.get(`/campaigns/${campaignId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    })
);

export const getCampaignsStatistics = createActionAsync(
  'GET_CAMPAIGNS_STATISTICS',
  (campaignsIds) => {
    let queryStr = '';

    if (!isEmpty(campaignsIds)) {
      queryStr = campaignsStatisticsUrlBuilder(campaignsIds);
    }
    return API.get(`/campaigns/statistic/${queryStr}`)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }
);

export const createNewCampaign = createActionAsync('CREATE_NEW_CAMPAIGN', (data) =>
  API.post('/campaigns', data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    })
);

export const getCampaignOptions = createActionAsync('GET_CAMPAIGN_OPTIONS', () =>
  API.get('/campaigns/options')
    .then((res) => res.data)
    .catch((err) => {
      throw err.response;
    })
);

export const getCampaignContactsList = createActionAsync(
  'GET_CAMPAIGN_CONTACTS_LIST',
  (countryTypeId, filters) => {
    let filtersQuery = newCampaignContactsUrlBuilder(filters);
    return API.get(`/lists/filtered?country_type_id=${countryTypeId}${decodeURI(filtersQuery)}`)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  }
);

export const getShortDomains = createActionAsync('GET_SHORT_DOMAINS', (countryTypeId) =>
  API.get(`/short-domains/available-for-campaign?country_type_id=${countryTypeId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    })
);

export const getInitialPredefinedBodies = createActionAsync(
  'GET_INITIAL_PREDEFINED_BODIES',
  (pbodyCategoryId, hasUrlId, encodingId) =>
    API.get(
      `/predefined-bodies?filter[category_id]=${pbodyCategoryId}&filter[has_code_url]=${hasUrlId}&filter[encoding]=${encodingId}&filter[active]=1`
    )
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      })
);

export const getSecondPredefinedBodies = createActionAsync(
  'GET_SECOND_PREDEFINED_BODIES',
  (pbodyCategoryId, hasUrlId, encodingId) =>
    API.get(
      `/predefined-bodies?filter[category_id]=${pbodyCategoryId}&filter[has_code_url]=${hasUrlId}&filter[encoding]=${encodingId}&filter[active]=1`
    )
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      })
);

export const getApis = createActionAsync('GET_APIS', (countryTypeId = null, typeId = '') => {
  let queryStr = 'filter[active]=1';

  if (countryTypeId) {
    queryStr += `&filter[country_type_id]=${countryTypeId}`;
  }
  if (typeId) {
    queryStr += `&filter[campaign_type_id]=${typeId}`;
  }

  return API.get(`/apis?${queryStr}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
});

export const getCarriers = createActionAsync('GET_CARRIERS', () => {
  return API.get('/carriers?filter[type]=Mobile')
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
});

export const getCampaignsLabels = createActionAsync('GET_CAMPAIGNS_LABELS', () => {
  return API.get('/campaigns/labels')
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
});

export const getIsnList = createActionAsync('GET_ISN_LIST', () => {
  return API.get('/isn/lists?filter[active]=1')
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
});
