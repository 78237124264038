import { combineReducers } from 'redux';
import authReducer from './auth';
import routeReducer from './route';
import campaignsReducer from './campaigns-reducer';

export default combineReducers({
  campaigns: campaignsReducer,
  route: routeReducer,
  auth: authReducer
});
