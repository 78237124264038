import { colors } from '@material-ui/core';

const white = 'hsl(0,0%,100%)',
  black = 'hsl(0,0%,0%)';

const palette = {
  black,
  white,
  borderRadius: '4px',
  aside: {
    backgroundColor: '#222D32',
    backgroundDisable: '#1E282C',
    color: white
  },
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
  default: {
    contrastText: white,
    dark: '#151c1e',
    main: '#1E282C',
    light: '#4c5457'
  },
  colors: {
    gradient: 'linear-gradient(159.13deg, #96D400 8.47%, #00BF37 91.39%)',
    color1: '#FDC02F',
    color2: '#BADA97',
    color3: '#CDDA49',
    color4: '#00BF37',
    color5: '#4054B2',
    color6: '#E93E5A',
    color7: '#F6F6F6',
    color8: '#222D32',
    color9: '#1E282C',
    color10: '#9E9E9E'
  },
  hoverColors: {
    color1: '#598E1F',
    color2: '#EFAC0D',
    color3: '#CD1F3B',
    color4: '#2F80ED',
    color5: '#32428E'
  },
  primary: {
    contrastText: white,
    dark: '#0665B2',
    main: '#1DAAF1',
    light: '#80C4FC'
  },
  secondary: {
    contrastText: white,
    dark: '#598E1F',
    main: '#8CC152',
    light: '#81E974'
  },
  custom: {
    contrastText: black,
    dark: '#598E1F',
    main: '#8CC152',
    light: '#81E974'
  },
  success: {
    contrastText: '#5A5A5A',
    color: '#4F8A10',
    dark: colors.green[900],
    main: '#DFF2BF',
    light: colors.green[400]
  },
  info: {
    contrastText: '#5A5A5A',
    color: '#00529B',
    dark: colors.blue[900],
    main: '#BDE5F8',
    light: colors.blue[400]
  },
  warning: {
    contrastText: '#5A5A5A',
    color: '#9F6000',
    dark: colors.orange[900],
    main: '#FEEFB3',
    light: colors.orange[400]
  },
  error: {
    contrastText: '#5A5A5A',
    color: '#D8000C',
    dark: colors.red[900],
    main: '#e40009',
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    disabled: colors.blueGrey[300],
    link: colors.blue[600]
  },
  background: {
    default: white,
    primary: '#f6f6f6',
    secondary: '#f5f5f5',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};

const getColor = (color = 'default', where = 'main') => palette[color][where];

export default { ...palette, getColor };
