import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, List, ListItem } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: 'column',
    width: '100%'
  },
  children: {
    display: 'block',
    width: '100%',
    '& svg': {
      width: '18px',
      marginLeft: '10px'
    }
  },
  parent: {
    display: 'block',
    width: '100%'
  },
  button: {
    width: '100%',
    borderRadius: 0,
    color: theme.palette.aside.color,
    padding: theme.spacing(1, 2, 1, 1),
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    height: '40px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#34491C',
      color: '#fff',
      '& $icon': {
        color: theme.palette.primary.main
      }
    }
  },
  icon: {
    color: theme.palette.aside.color,
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    '& > img': {
      width: 10,
      height: 10
    }
  },
  active: {
    backgroundColor: '#8CC152',
    color: '#fff',
    fontWeight: 500,
    '& $icon': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff',
      '& $icon': {
        color: '#fff'
      }
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ display: 'block', width: '100%' }}>
    <RouterLink {...props} />
  </div>
));

const CustomRouterLinkChild = forwardRef((props, ref) => (
  <div ref={ref} style={{ display: 'block', width: '100%' }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
          >
            <div className={classes.icon}>
              <img src={page.icon} alt={page.title} />
            </div>
            {page.title}
          </Button>
          {page.children &&
            page.children.map((childrenPage) => (
              <div className={classes.children} key={childrenPage.title}>
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLinkChild}
                  to={childrenPage.href}
                >
                  <div className={classes.icon}>
                    <AddIcon />
                  </div>
                  {childrenPage.title}
                </Button>
              </div>
            ))}
        </ListItem>
      ))}
      {props.children}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
