import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';

import { SidebarNav } from './components';
import icoCampaign from 'assets/images/menu/ico-campaign.svg';
import icoDashboard from 'assets/images/menu/ico-dashboard.svg';

const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: 1100,
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    },
    transition: 'all 5700ms linear'
  },
  root: {
    ...theme.palette.aside,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 0
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2),
    paddingTop: 0
  },
  button: {
    width: '100%',
    borderRadius: 0,
    color: theme.palette.aside.color,
    padding: theme.spacing(1, 2, 1, 1),
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  active: {
    color: theme.palette.primary.light,
    fontWeight: 500
  },
  children: {
    display: 'block',
    width: '100%'
  }
}));

const Sidebar = (props) => {
  const { open, isDesktop, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: icoDashboard
    },
    {
      title: 'Campaigns',
      href: '/campaigns',
      icon: icoCampaign,
      children: [
        {
          title: 'Add New',
          href: '/campaigns/create'
        }
      ]
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        {!isDesktop && (
          <div
            style={{
              height: '64px'
            }}
          >
            &nbsp;
          </div>
        )}

        <SidebarNav className={classes.nav} pages={pages}></SidebarNav>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
