import API from 'api/API';
import { history } from 'components/App';

export const checkMe = () => {
    let request = API.get('/auth/me');

    return (dispatch) => {
      return request
        .then((response) => {
          dispatch({ type: 'AUTH_CHECK', payload: response.data });
        })
        .catch((error) => {
          if ('status' in error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            dispatch({ type: 'ROUTE_LOADING', payload: false });
            dispatch({ type: 'AUTH_LOGOUT' });
            history.push('/sign-in');
          } else {
            console.warn('AUTH_CHECK_ERROR: ' + error.response);
          }
        })
        .finally(function () {
          dispatch({ type: 'ROUTE_LOADING', payload: false });
        });
    };
  },
  setLoading = (active) => {
    return (dispatch) => {
      dispatch({ type: 'ROUTE_LOADING', payload: !!active });
    };
  };
