import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Sidebar, Topbar } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    height: '100%'
  },
  application: {
    height: '100%'
  },
  contentBox: {
    height: '100%',
    background: '#F5F5F5',
    overflow: 'auto'
  },
  shiftContent: {
    paddingLeft: 240
  },
  mainWrapper: {
    padding: theme.spacing(4),
    minHeight: '100vh',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  }
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarToggle = () => setOpenSidebar(!openSidebar);

  const handleSidebarClose = () => setOpenSidebar(false);

  const shouldOpenSidebar = isDesktop || openSidebar;

  return (
    <div className={classes.application}>
      <div className={clsx({ [classes.root]: true, [classes.shiftContent]: isDesktop })}>
        <Topbar
          isDesktop={isDesktop}
          sidebarOpen={shouldOpenSidebar}
          onSidebarOpen={handleSidebarToggle}
        />
        <div className={classes.contentBox}>
          <Sidebar
            isDesktop={isDesktop}
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={!isDesktop ? 'persistent' : 'permanent'}
          />
          <main className={classes.mainWrapper}>{children}</main>
        </div>
      </div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
