import React from 'react';
import theme from '../theme';
import Routes from '../routes';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

// Styles
import 'react-day-picker/lib/style.css';

export const history = createBrowserHistory();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
