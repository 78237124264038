import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  (theme) => ({
    app: {
      height: '100%'
    },
    main: {
      height: '100%'
    }
  }),
  { name: 'layout' }
);

const Minimal = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.app}>
      <main className={classes.main}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
